import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/chevron-right-gray.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stage-btn-link-container", { 'stage-btn-link-container-hover' : $setup.props.hoverStageId === $setup.props.stage.id }]),
    onMouseover: $setup.onHover,
    onMouseleave: $setup.onMouseLeave,
    onClick: $setup.onClick
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(["stage-btn-title stage", {'title-hover' : $setup.hover}])
    }, "Stage " + _toDisplayString($setup.props.stage.title), 3),
    _createElementVNode("p", {
      class: _normalizeClass(["stage-btn-title available-lots", {'title-hover' : $setup.hover}])
    }, _toDisplayString($setup.props.lotsAvailableAmount) + " Available Lots", 3),
    _createElementVNode("img", {
      class: _normalizeClass(["stage-btn-chevron", {'title-hover' : $setup.hover}]),
      src: _imports_0
    }, null, 2)
  ], 34))
}