import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a9c4432"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "image-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["builder-slider scroll-wrapper", { 'select-design': $setup.props.isSelectDesign, 'select-facade': $setup.props.isSelectFacade }])
  }, [
    ($setup.isDesktop)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["builder-images-wr", { 'select-design': $setup.props.isSelectDesign, 'select-facade': $setup.props.isSelectFacade }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedImages, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["content-images", { checkmark: $setup.props.selectedimage?.name === image.name, 'select-facade': $setup.props.isSelectFacade }]),
              key: index,
              onClick: ($event: any) => ($setup.onSelectImage(image))
            }, [
              _createElementVNode("img", {
                class: _normalizeClass(["builder-carousel-img", { 'select-facade': $setup.props.isSelectFacade }]),
                src: image.mainImage || image.url
              }, null, 10, _hoisted_2),
              _createElementVNode("span", {
                class: _normalizeClass(["image-text", { 'select-facade': $setup.props.isSelectFacade }])
              }, _toDisplayString(image.name), 3)
            ], 10, _hoisted_1))
          }), 128))
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "builder-carousel-wr",
          ref: "builderCarousel",
          onScroll: $setup.handleScroll
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedImages, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["content-images", { checkmark: $setup.props.selectedimage?.name === image.name }]),
              key: index,
              onClick: ($event: any) => ($setup.onSelectImage(image))
            }, [
              _createElementVNode("img", {
                class: "builder-carousel-img",
                src: image.mainImage || image.url
              }, null, 8, _hoisted_4),
              _createElementVNode("span", _hoisted_5, _toDisplayString(image.name), 1)
            ], 10, _hoisted_3))
          }), 128))
        ], 544))
  ], 2))
}