import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "carousel-container" }
const _hoisted_2 = { class: "facade-name" }
const _hoisted_3 = { class: "price-facade" }
const _hoisted_4 = { class: "carousel__item__facade" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Carousel"], {
      currentSlide: $setup.currentSlideIndex,
      "onUpdate:currentSlide": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentSlideIndex) = $event)),
      transition: 200,
      navigationEnabled: true,
      paginationEnabled: true,
      wrapAround: $setup.getSliderNavigation
    }, {
      addons: _withCtx(() => [
        ($setup.getSliderNavigation)
          ? (_openBlock(), _createBlock($setup["Pagination"], { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (!$setup.props.isCardHouseDesign && $setup.props.selectedHouseDesign)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.props.selectedHouseDesign?.facades, (facade, index) => {
              return (_openBlock(), _createBlock($setup["Slide"], { key: index }, {
                default: _withCtx(() => [
                  _createVNode($setup["ToggleButton"], {
                    modelValue: $setup.selectedFacadeIds[facade._id],
                    "onUpdate:modelValue": ($event: any) => (($setup.selectedFacadeIds[facade._id]) = $event),
                    additionalClass: ['large', 'margin'],
                    labelBold: 'toggle-title-font bolder',
                    labelClass: 'flex-column-reverse',
                    toggleClass: 'position-absolute',
                    labelHidden: !$setup.selectedFacadeIds[facade._id] ? 'hidden-text' : '',
                    label: $setup.selectedFacadeIds[facade._id] ? 'Selected' : 'Not Selected',
                    facadeId: facade._id,
                    "onUpdate:selectedFacade": $setup.updateSelectedFacade
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "labelHidden", "label", "facadeId"]),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(facade.name), 1),
                  _createElementVNode("div", _hoisted_3, _toDisplayString($setup.isNoAdditionalCharge(facade.price) ? 'No Additional Charge' : $setup.formatPrice(facade.price)), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: facade.url,
                      alt: "Facade Image"
                    }, null, 8, _hoisted_5)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          : _createCommentVNode("", true),
        ($setup.props.isCardHouseDesign && $setup.props.houses)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.props.houses, (house, index) => {
              return (_openBlock(), _createBlock($setup["Slide"], { key: index }, {
                default: _withCtx(() => [
                  _createVNode($setup["HouseCard"], {
                    house: house,
                    estate: $setup.props.estate,
                    quote: $setup.props.quote,
                    result: $setup.props.result,
                    houseDetailsView: $setup.props.houseDetailsView
                  }, null, 8, ["house", "estate", "quote", "result", "houseDetailsView"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["currentSlide", "wrapAround"])
  ], 512)), [
    [_vShow, $props.isOpen]
  ])
}